// src/components/store/reducers/modalReducer.js

import { TOGGLE_POPUP } from "../actions/cartPopupActions";

const initialState = {
  isOpen: false,
};

const modalReducer = (state = initialState, action) => {
  console.log(state.isOpen);
  switch (action.type) {
    case TOGGLE_POPUP:
      return {
        ...state,
        isOpen: !state.isOpen,
      };

    default:
      return state;
  }
};

export default modalReducer;
