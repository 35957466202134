// src/store/reducers/tabReducer.js
import { SET_ACTIVE_TAB } from "../actions/customerActions";

const initialState = "Оплата";

const tabReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return action.payload;
    default:
      return state;
  }
};

export default tabReducer;
