import React, { useState, useEffect } from "react";
import style from "./CategoriesSlider.module.scss";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCategory } from "../store/actions/categoryActions";

import banner1 from "../../assets/banners/banner1.png";
import banner2 from "../../assets/banners/banner2.png";
import banner3 from "../../assets/banners/banner3.png";
import banner4 from "../../assets/banners/banner4.png";

function CategoriesSlider() {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const items = [
    banner1,
    banner2,
    banner3,
    banner4,
    banner1,
    banner2,
    banner3,
    banner4,
  ];
  const widthToMove = 18.2;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 3 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 3 ? 0 : prevIndex + 1
    );
  };

  const getLink = (index) => {
    switch (index) {
      case 1:
        return "/product/31";
      case 3:
        return "/product/13";
      case 5:
        return "/product/31";
      case 7:
        return "/product/13";
      default:
        return "/";
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 10000); // 10 секунд

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex]); // залежність від currentIndex

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.categoriesList}>
          <div className={style.categoryContainer}>
            <Link
              className={style.category}
              to="/catalog"
              onClick={() => {
                dispatch(setCategory("Акумулятори"));
              }}
            >
              Акумулятори
            </Link>
            <ChevronRight className={style.searchIcon} />
          </div>
          <div className={style.categoryContainer}>
            <Link
              className={style.category}
              to="/catalog"
              onClick={() => {
                dispatch(setCategory("Вантажні"));
              }}
            >
              Вантажні
            </Link>
            <ChevronRight className={style.searchIcon} />
          </div>
          <div className={style.categoryContainer}>
            <Link
              className={style.category}
              to="/catalog"
              onClick={() => {
                dispatch(setCategory("Відключення світла"));
              }}
            >
              Відключення світла та <p>під інвертор</p>
            </Link>
            <ChevronRight className={style.searchIcon} />
          </div>
        </div>
        <div className={style.slider}>
          <div
            className={style.sliderTrack}
            style={{
              transform: `translateX(-${currentIndex * widthToMove}rem)`,
            }}
          >
            {items.map((item, index) => (
              <Link
                key={index}
                to={getLink(index)}
                className={style.sliderItem}
              >
                <img
                  className={style.sliderImg}
                  src={item}
                  alt={`Slide ${index + 1}`}
                />
              </Link>
            ))}
          </div>
          <button className={style.prev} onClick={handlePrev}>
            <ChevronLeft className={style.searchIcon} />
          </button>
          <button className={style.next} onClick={handleNext}>
            <ChevronRight className={style.searchIcon} />
          </button>
          <div className={style.sliderNavigation}>
            {items.slice(0, items.length - 2).map((_, index) => (
              <div
                key={index}
                onClick={() => {
                  setCurrentIndex(index);
                }}
                className={`${style.sliderNavigationItem} ${
                  currentIndex === index ? style.activeNavigationItem : ""
                }`}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoriesSlider;
