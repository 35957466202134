import React from "react";
import style from "./Client.module.scss";
function PrivaticyPolicy() {
  return (
    <div>
      <div className={style.termsContainer}>
        <h1 className={style.title}>ОСНОВНІ ПОНЯТТЯ ТА ВИЗНАЧЕННЯ ТЕРМІНІВ</h1>
        <div className={style.term}>
          <h2 className={style.termTitle}>Публічна оферта</h2>
          <p className={style.termDescription}>
            Пропозиція Продавця, адресована невизначеному колу фізичних осіб
            відповідно до ст. 641 Цивільного кодексу України, укласти з нею
            договір на умовах, що містяться в ній.
          </p>
        </div>
        <div className={style.term}>
          <h2 className={style.termTitle}>Договір</h2>
          <p className={style.termDescription}>
            Цей Договір купівлі-продажу товарів, який укладений на умовах
            визначених Публічною офертою з моменту її Акцепту (прийняття)
            Покупцем та встановлює однакові для всіх Покупців умови.
          </p>
        </div>
        <div className={style.term}>
          <h2 className={style.termTitle}>Акцепт</h2>
          <p className={style.termDescription}>
            Надання Покупцем повної і безумовної відповіді Продавцю на його
            пропозицію укласти Договір на умовах, що визначені публічною офертою
            шляхом оформлення замовлення на Сайті та/або підписання бланку
            Замовлення Товарів у випадку оформлення Замовлення у офісі Продавця
            та/або здійснення Покупцем оплати вартості Товару, що свідчить про
            прийняття ним публічної оферти.
          </p>
        </div>
        <div className={style.term}>
          <h2 className={style.termTitle}>Покупець</h2>
          <p className={style.termDescription}>
            Будь-яка дієздатна фізична особа, згідно чинного законодавства
            України, яка має на меті придбання Товарів для особистого,
            домашнього або іншого використання, не пов’язаного з підприємницькою
            діяльністю або виконанням обов’язків найманого працівника, яка
            Акцептувала умови цієї Публічної оферти і оформила Замовлення
            засобами інформаційної системи Сайту.
          </p>
        </div>
        <div className={style.term}>
          <h2 className={style.termTitle}>Замовлення</h2>
          <p className={style.termDescription}>
            Заявка Покупця на купівлю обраних ним Товарів, яка оформлена і
            надана Продавцю за допомогою програмних засобів Сайту або шляхом
            заповнення бланку Замовлення або іншим способом.
          </p>
        </div>
        <div className={style.term}>
          <h2 className={style.termTitle}>Сторона</h2>
          <p className={style.termDescription}>
            Продавець або Покупець в залежності від контексту.
          </p>
        </div>
        <div className={style.term}>
          <h2 className={style.termTitle}>Сторони</h2>
          <p className={style.termDescription}>
            Продавець та Покупець, що уклали Договір відповідно до умов,
            передбачених ним та чинним законодавством України.
          </p>
        </div>
        <div className={style.term}>
          <h2 className={style.termTitle}>Реєстрація</h2>
          <p className={style.termDescription}>
            Заповнення Покупцем відповідної реєстраційної форми на Сайті з
            зазначенням усіх необхідних даних.
          </p>
        </div>
        <div className={style.term}>
          <h2 className={style.termTitle}>Всі інші терміни</h2>
          <p className={style.termDescription}>
            Вживаються у значенні визначеному згідно чинного законодавства
            України.
          </p>
        </div>
      </div>
      <div className={style.provisionsContainer}>
        <h1 className={style.title}>ЗАГАЛЬНІ ПОЛОЖЕННЯ</h1>
        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.1.</h2>
          <p className={style.provisionDescription}>
            Договір купівлі-продажу Товарів укладається шляхом приєднання
            Покупцем до запропонованої Продавцем Публічної оферти в цілому.
            Приєднання Покупця до Публічної оферти здійснюється шляхом її
            Акцепту.
          </p>
        </div>
        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.2.</h2>
          <p className={style.provisionDescription}>
            Договір вважається укладеним з моменту Акцепту Покупцем всіх
            істотних умов Договору, викладених в цій Публічній оферті, без
            підпису письмового примірника Сторонами. Цей Договір має юридичну
            силу відповідно до ст.ст. 634, 638 Цивільного кодексу України і є
            рівнозначним договору, підписаному Сторонами.
          </p>
        </div>
        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.3.</h2>
          <p className={style.provisionDescription}>
            З моменту здійснення Акцепту (прийняття) Публічної оферти Покупець
            зобов’язується дотримуватися умов та положень Договору та всіх
            додатків, що є його невід’ємною складовою частиною.
          </p>
        </div>
        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.4.</h2>
          <p className={style.provisionDescription}>
            Підтвердженням згоди Покупця укласти Договір, тобто повного та
            безумовного Акцепту (прийняття) Публічної оферти є вчинення таких
            дій:
          </p>
          <ul className={style.subList}>
            <li>
              <strong>2.4.1.</strong> Проставлення Покупцем на Інтернет-сторінці
              оформлення Замовлення відмітки у полі «Згоден з умовами Публічної
              оферти» та завершення оформлення Замовлення Товарів за допомогою
              інформаційної системи Сайту. При цьому, розміщена на екранній
              формі відповідної Інтернет-сторінки Сайту фраза може відрізнятися
              від наведених в лапках у цьому абзаці вище, але має бути
              аналогічною за значенням та містити в собі або поряд з собою (на
              тій самій екранній формі) посилання на текст Публічної оферти.
            </li>
            <li>
              <strong>2.4.2.</strong> Підписання бланку Замовлення Товарів у
              випадку оформлення Замовлення у офісі Продавця.
            </li>
            <li>
              <strong>2.4.3.</strong> Здійснення Покупцем оплати замовлених
              Товарів.
            </li>
          </ul>
        </div>
        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.5.</h2>
          <p className={style.provisionDescription}>
            Продавець на письмову вимогу Покупця може надати йому завірену
            печаткою письмову форму цього Договору.
          </p>
        </div>
        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.6.</h2>
          <p className={style.provisionDescription}>
            Факт отримання Продавцем Замовлення підтверджується шляхом
            надіслання Покупцю повідомлення, що містить інформацію про
            оформлення Замовлення. Повідомлення про отримання Замовлення
            надсилається Покупцю в електронному вигляді за допомогою служби
            коротких текстових повідомлень (SMS-повідомлень) на контактний номер
            мобільного телефону, вказаний Покупцем при оформленні Замовлення або
            при Реєстрації на Сайті та/або електронного листа на адресу
            електронної пошти, вказану Покупцем при Реєстрації на Сайті.
          </p>
        </div>
        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.7.</h2>
          <p className={style.provisionDescription}>
            Кожна Сторона гарантує іншій Стороні, що володіє необхідною
            дієздатністю, а рівно всіма правами і повноваженнями, необхідними і
            достатніми для укладання і виконання даного Договору відповідно до
            його умов.
          </p>
        </div>
        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.8.</h2>
          <p className={style.provisionDescription}>
            Усі умови Договору, викладені в цій Публічній оферті, є
            обов’язковими для Сторін. Перед початком оформлення Замовлення
            Товарів кожний Покупець зобов’язаний ознайомитися з умовами цієї
            Публічної оферти, що розміщені (оприлюднені) на Сайті Продавця.
          </p>
        </div>
        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.9.</h2>
          <p className={style.provisionDescription}>
            Якщо Покупець не згоден з умовами Публічної оферти, то він має
            утриматись від оформлення або розміщення Замовлення. Якщо Покупець
            здійснив Акцепт, то він підтверджує своє ознайомлення та згоду з
            усіма умовами Договору.
          </p>
        </div>
        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.10.</h2>
          <p className={style.provisionDescription}>
            Продавець самостійно визначає умови Публічної оферти та має право
            вносити зміни до її умов з обов’язковим повідомленням про це
            Покупців, шляхом розміщення нової редакції Публічної оферти на
            Сайті.
          </p>
        </div>
      </div>
      <div className={style.provisionsContainer}>
        <h1 className={style.title}>ПРЕДМЕТ ДОГОВОРУ</h1>
        <div className={style.provision}>
          <h2 className={style.provisionTitle}>3.1.</h2>
          <p className={style.provisionDescription}>
            Продавець зобов’язується поставити і передати у власність Покупця, а
            Покупець зобов’язується сплатити і своєчасно прийняти запасні
            частини та інші товари представлені в асортименті продавця (далі –
            «Товар»).
          </p>
        </div>
        <div className={style.provision}>
          <h2 className={style.provisionTitle}>3.2.</h2>
          <p className={style.provisionDescription}>
            Покупець замовляє та купує Товар для особистого, домашнього або
            іншого використання, не пов’язаного з підприємницькою діяльністю або
            виконанням обов’язків найманого працівника.
          </p>
        </div>
        <div className={style.provision}>
          <h2 className={style.provisionTitle}>3.3.</h2>
          <p className={style.provisionDescription}>
            Продавець здійснює продаж Товару на підставі Замовлення, яке
            Покупець оформлює самостійно на Сайті або за допомогою Продавця.
            Замовлення стає невід’ємною частиною Договору з моменту його
            оформлення.
          </p>
        </div>
        <div className={style.provision}>
          <h2 className={style.provisionTitle}>3.4.</h2>
          <p className={style.provisionDescription}>
            Товар характеризується артикулем, очікуваним строком поставки,
            ціною. Інформація про Товар розміщена на Сайті та може бути уточнена
            у Продавця.
          </p>
        </div>
        <div className={style.provision}>
          <h2 className={style.provisionTitle}>3.5.</h2>
          <p className={style.provisionDescription}>
            Деякі представлені на Сайті Товари є пропозиціями партнерських
            складів Продавця і поставляються виключно на підставі
            індивідуального Замовлення Покупця.
          </p>
        </div>
      </div>
      <div className={style.provisionsContainer}>
        <h1 className={style.title}>4. ПОРЯДОК ВИКОНАННЯ ЗАМОВЛЕННЯ</h1>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>4.1.</h2>
          <p className={style.provisionDescription}>
            Для більшості Товарів на Сайті вказано очікуваний термін надходження
            до магазину продавця або складу служби перевізника.
          </p>
          <div className={style.subProvision}>
            <h3 className={style.subProvisionTitle}>4.1.1.</h3>
            <p className={style.subProvisionDescription}>
              Очікувані терміни є середньостатистичними.
            </p>
            <h3 className={style.subProvisionTitle}>4.1.2.</h3>
            <p className={style.subProvisionDescription}>
              Якщо виконання Замовлення протягом очікуваного терміну не
              відбулося, Продавець без погодження з Покупцем подовжує виконання
              Замовлення.
            </p>
          </div>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>4.2.</h2>
          <p className={style.provisionDescription}>
            Виконання Замовлення починається, як правило, після надходження
            авансового платежу на рахунок Продавця або підтвердження Покупцем
            його оплати. За домовленістю Сторін виконання Замовлення може бути
            розпочато без попередньої оплати з оплатою за фактом
            прийому-передачі.
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>4.3.</h2>
          <p className={style.provisionDescription}>
            Замовлення може бути виконано повністю або частково, а також може
            бути анульоване.
          </p>
          <div className={style.subProvision}>
            <h3 className={style.subProvisionTitle}>4.3.1.</h3>
            <p className={style.subProvisionDescription}>
              Часткове виконання Замовлення або його анулювання відбувається у
              разі, коли в ході його виконання обраний за конкретними умовами
              (виробником, очікуваним строком поставки, ціною) Товар виявився
              відсутнім на відповідному партнерському складі.
            </p>
            <h3 className={style.subProvisionTitle}>4.3.2.</h3>
            <p className={style.subProvisionDescription}>
              Замовлення вважається виконаним (повністю або частково) в момент
              надходження Товару до магазину або відділень служби перевізника.
            </p>
          </div>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>4.4.</h2>
          <p className={style.provisionDescription}>
            Продавець здійснює доставку Товару на умовах, вказаних на Сайті в
            розділі «Доставка».
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>4.5.</h2>
          <p className={style.provisionDescription}>
            Право власності на Товар та ризики його випадкового пошкодження або
            знищення переходять від Продавця до Покупця:
          </p>
          <div className={style.subProvision}>
            <h3 className={style.subProvisionTitle}>4.5.1.</h3>
            <p className={style.subProvisionDescription}>
              З моменту фактичного прийому-передачі Товару при його отриманні
              Покупцем в магазині, від кур’єра Продавця, а також від кур’єра або
              у відділенні транспортних компаній, які вказані на Сайті в розділі
              «Доставка».
            </p>
            <h3 className={style.subProvisionTitle}>4.5.2.</h3>
            <p className={style.subProvisionDescription}>
              З моменту передачі Товару перевізнику при доставці іншими
              транспортними компаніями.
            </p>
          </div>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>4.6.</h2>
          <p className={style.provisionDescription}>
            Покупець зобов’язаний прийняти Товар або вказати адресу для його
            доставки протягом 3 (трьох) робочих днів з моменту надходження
            Товару в магазин (реєстрації).
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>4.7.</h2>
          <p className={style.provisionDescription}>
            Товар може бути повернений Продавцю у порядку та строки, передбачені
            чинним законодавством України. При цьому, Сторони погоджуються з
            наступними умовами:
          </p>
          <div className={style.subProvision}>
            <h3 className={style.subProvisionTitle}>4.7.1.</h3>
            <p className={style.subProvisionDescription}>
              Місцем повернення Товару є магазин Продавця.
            </p>
            <h3 className={style.subProvisionTitle}>4.7.2.</h3>
            <p className={style.subProvisionDescription}>
              Спосіб і дата відправки Товару мають бути погоджені з Продавцем.
            </p>
          </div>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>4.8.</h2>
          <p className={style.provisionDescription}>
            Контакти між Сторонами здійснюється переважно електронним способом.
          </p>
          <div className={style.subProvision}>
            <h3 className={style.subProvisionTitle}>4.8.1.</h3>
            <p className={style.subProvisionDescription}>
              Хід виконання і стан Замовлення відображається на Сайті в
              «Особистому кабінеті» Покупця.
            </p>
            <h3 className={style.subProvisionTitle}>4.8.2.</h3>
            <p className={style.subProvisionDescription}>
              Покупець може налаштувати оповіщення про хід виконання Замовлення
              електронною поштою та/або за допомогою SMS-повідомлень (вказавши
              контактний номер мобільного телефону у міжнародному форматі,
              наприклад +380673331100).
            </p>
          </div>
        </div>
      </div>
      <div className={style.provisionsContainer}>
        <h1 className={style.title}>6. ЦІНА ТА ПОРЯДОК ОПЛАТИ</h1>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>6.1.</h2>
          <p className={style.provisionDescription}>
            Загальна ціна Замовлення визначається як сума цін Товарів, що
            складають Замовлення.
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>6.2.</h2>
          <p className={style.provisionDescription}>
            Ціни за Товар відображені на Сайті і визначені на умовах
            самостійного вивозу з магазину продажу.
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>6.3.</h2>
          <p className={style.provisionDescription}>
            Оплата за Товар здійснюється у гривнях на підставі Замовлення:
          </p>
          <div className={style.subProvision}>
            <h3 className={style.subProvisionTitle}>6.3.1.</h3>
            <p className={style.subProvisionDescription}>
              Платіжною карткою на Сайті за допомогою технічних засобів, які
              надаються належною юридичною особою, що виконує функції
              інтернет-еквайрера на підставі відповідної угоди з Продавцем.
            </p>
            <h3 className={style.subProvisionTitle}>6.3.2.</h3>
            <p className={style.subProvisionDescription}>
              Внесенням грошових коштів на банківський рахунок Продавця.
            </p>
          </div>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>6.4.</h2>
          <p className={style.provisionDescription}>
            Повернення сплачених грошових коштів здійснюється на підставі заяви
            Покупця у порядку та строки, передбачені чинним законодавством
            України.
          </p>
          <div className={style.subProvision}>
            <h3 className={style.subProvisionTitle}>6.4.1.</h3>
            <p className={style.subProvisionDescription}>
              Повернення коштів здійснюється на поточний або картковий рахунок,
              вказаний Покупцем.
            </p>
            <h3 className={style.subProvisionTitle}>6.4.2.</h3>
            <p className={style.subProvisionDescription}>
              Покупець має право надавати заяву на повернення грошових коштів в
              електронній формі за умови її надсилання із електронної адреси,
              яка була вказана Покупцем при Реєстрації на Сайті.
            </p>
          </div>
        </div>
      </div>
      <div className={style.provisionsContainer}>
        <h1 className={style.title}>
          7. ЯКІСТЬ ТОВАРУ ТА ГАРАНТІЙНІ ЗОБ’ЯЗАННЯ
        </h1>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>7.1.</h2>
          <p className={style.provisionDescription}>
            Продавець передає Покупцю Товар, який відповідає вимогам щодо його
            якості та придатний для мети, з якою Товар такого роду звичайно
            використовується.
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>7.2.</h2>
          <p className={style.provisionDescription}>
            Прийом-передача Товару за асортиментом, кількістю, комплектністю і
            якістю здійснюється на підставі видаткової накладної.
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>7.3.</h2>
          <p className={style.provisionDescription}>
            Претензії Покупця щодо асортименту, кількості, комплектності і
            якості Товару, за виключенням прихованих дефектів, приймаються в
            ході прийому-передачі Товару в магазин, від кур’єра або у відділенні
            транспортної компанії.
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>7.4.</h2>
          <p className={style.provisionDescription}>
            Продавець надає гарантії якості Товару згідно вимог до якості, які
            встановлені на території України.
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>7.5.</h2>
          <p className={style.provisionDescription}>
            Гарантійний строк на Товар, умови виконання гарантійних зобов’язань
            Продавця, порядок звернення з гарантійною претензією, а також
            порядок повернення Товару визначені у «Положенні про гарантію та
            умови повернення товарів, придбаних в інтернет-магазині ATL.UA»
            (Додаток №1), що є невід’ємною частиною Договору.
          </p>
        </div>
      </div>
      <div className={style.provisionsContainer}>
        <h1 className={style.title}>8. ВІДПОВІДАЛЬНІСТЬ СТОРІН</h1>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>8.1.</h2>
          <p className={style.provisionDescription}>
            Сторони несуть відповідальність за невиконання або неналежне
            зобов’язань за Договором згідно з чинним законодавством України.
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>8.2.</h2>
          <p className={style.provisionDescription}>
            Сторони звільняються від відповідальності за повне або часткове
            невиконання своїх зобов'язань, якщо невиконання є наслідком дії
            обставин непереборної сили, таких як: війна або воєнні дії,
            землетрус, повінь, пожежа й інші стихійні лиха, акти або дії органів
            державної влади, зміна митних правил, обмеження імпорту й експорту,
            що виникли незалежно від волі Сторін після укладання Договору.
            Сторона, що не може виконати своїх зобов'язань, негайно сповіщає про
            виникнення таких обставин іншу Сторону і надає документи, що
            підтверджують наявність таких обставин, видані уповноваженими на те
            органами.
          </p>
        </div>
      </div>
      <div className={style.provisionsContainer}>
        <div className={style.title}>9. ПРИКІНЦЕВІ ПОЛОЖЕННЯ</div>

        <div className={style.provision}>
          <div className={style.provisionTitle}>9.1.</div>
          <div className={style.provisionDescription}>
            Кожен Договір діє по відношенню до одного конкретного Замовлення,
            набуває чинності з моменту Акцепту Публічної оферти та діє до
            виконання Сторонами своїх зобов’язань за ним.
          </div>
        </div>

        <div className={style.provision}>
          <div className={style.provisionTitle}>9.2.</div>
          <div className={style.provisionDescription}>
            Усі умови Договору між Продавцем та Покупцем, в тому числі істотні,
            наведені у цій Публічній оферті, розміщені у мережі Інтернет за
            адресою: , на дату укладення Договору.
          </div>
        </div>

        <div className={style.provision}>
          <div className={style.provisionTitle}>9.3.</div>
          <div className={style.provisionDescription}>
            Усі зміни Публічної оферти публікуються в мережі Інтернет за
            адресою: зі збереженням усіх попередніх редакцій за тією ж адресою.
          </div>
        </div>

        <div className={style.provision}>
          <div className={style.provisionTitle}>9.4.</div>
          <div className={style.provisionDescription}>
            Опубліковані зміни вважаються доведеними до відома Покупця в повному
            обсязі, набирають чинності з дати їх опублікування.
          </div>
        </div>

        <div className={style.provision}>
          <div className={style.provisionTitle}>9.5.</div>
          <div className={style.provisionDescription}>
            Сторони визнають та погоджуються, що належними способами обміну
            інформацією є повідомлення, заяви та інші форми листування між
            Сторонами.
          </div>
        </div>

        <div className={style.provision}>
          <div className={style.provisionTitle}>9.6.</div>
          <div className={style.provisionDescription}>
            Покупець погоджується на обробку його персональних даних відповідно
            до чинного законодавства України.
          </div>
        </div>

        <div className={style.provision}>
          <div className={style.provisionTitle}>9.7.</div>
          <div className={style.provisionDescription}>
            Покупець несе повну відповідальність за правильність вказаних ним
            даних і зобов'язується повідомляти Продавця про зміни.
          </div>
        </div>
      </div>
      <div className={style.provisionsContainer}>
        <div className={style.title}>9. ПРИКІНЦЕВІ ПОЛОЖЕННЯ</div>

        <div className={style.provision}>
          <div className={style.provisionTitle}>9.1.</div>
          <div className={style.provisionDescription}>
            Кожен Договір діє по відношенню до одного конкретного Замовлення,
            набуває чинності з моменту Акцепту Публічної оферти та діє до
            виконання Сторонами своїх зобов’язань за ним.
          </div>
        </div>

        <div className={style.provision}>
          <div className={style.provisionTitle}>9.2.</div>
          <div className={style.provisionDescription}>
            Усі умови Договору між Продавцем та Покупцем, в тому числі істотні,
            наведені у цій Публічній оферті, розміщені у мережі Інтернет за
            адресою: , на дату укладення Договору.
          </div>
        </div>

        <div className={style.provision}>
          <div className={style.provisionTitle}>9.3.</div>
          <div className={style.provisionDescription}>
            Усі зміни Публічної оферти публікуються в мережі Інтернет за
            адресою: зі збереженням усіх попередніх редакцій за тією ж адресою.
          </div>
        </div>

        <div className={style.provision}>
          <div className={style.provisionTitle}>9.4.</div>
          <div className={style.provisionDescription}>
            Опубліковані зміни вважаються доведеними до відома Покупця в повному
            обсязі, набирають чинності з дати їх опублікування.
          </div>
        </div>

        <div className={style.provision}>
          <div className={style.provisionTitle}>9.5.</div>
          <div className={style.provisionDescription}>
            Сторони визнають та погоджуються, що належними способами обміну
            інформацією є повідомлення, заяви та інші форми листування між
            Сторонами.
          </div>
        </div>

        <div className={style.provision}>
          <div className={style.provisionTitle}>9.6.</div>
          <div className={style.provisionDescription}>
            Покупець погоджується на обробку його персональних даних відповідно
            до чинного законодавства України.
          </div>
        </div>

        <div className={style.provision}>
          <div className={style.provisionTitle}>9.7.</div>
          <div className={style.provisionDescription}>
            Покупець несе повну відповідальність за правильність вказаних ним
            даних і зобов'язується повідомляти Продавця про зміни.
          </div>
        </div>
      </div>
      <div className={style.provisionsContainer}>
        <h1 className={style.title}>
          2. ГАРАНТІЙНІ СТРОКИ ТА УМОВИ ДІЇ ГАРАНТІЇ
        </h1>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.1.</h2>
          <p className={style.provisionDescription}>
            Гарантійний строк на Товар є не меншим за 6 (шість) місяців з дати
            прийому-передачі, а у разі потреби його спеціальної установки – з
            дати установки або 20 000 км пробігу (що наступить раніше). Є
            товари, в яких немає гарантії, та гарантії згідно регламенту
            виробника.
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.2.</h2>
          <p className={style.provisionDescription}>
            Виробники можуть надавати розширену гарантію згідно їх умов, які
            викладені на Сайті у розділі «Гарантія і повернення товарів».
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.3.</h2>
          <p className={style.provisionDescription}>
            Гарантія на автомобільні шини діє до моменту монтажу Товару на
            транспортний засіб. При виникненні претензії до якості шин в ході
            експлуатації претензія розглядається за умови надання технічного
            висновку від сертифікованої «УКРСЕПРО» організації про причини
            виникнення дефекту та непридатність Товару до подальшої
            експлуатації.
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.4.</h2>
          <p className={style.provisionDescription}>
            Гарантійний строк на Товар, встановлений на автомобілі, які
            використовуються в таксі, службах перевезення або беруть участь в
            спортивних змаганнях, зменшується втричі.
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.5.</h2>
          <p className={style.provisionDescription}>
            Гарантія діє за умови дотримання Покупцем наступних вимог:
          </p>
          <ul className={style.list}>
            <li>2.5.1. Товар відповідає специфікації автомобіля.</li>
            <li>
              2.5.2. Товар встановлено на автомобіль на СТО, що підтверджується
              наданням замовлення-наряду.
            </li>
            <li>
              2.5.3. Непрацездатність (дефектність) Товару підтверджена Актом
              рекламації.
            </li>
            <li>
              2.5.4. Гарантійна претензія висунута протягом 10 календарних днів
              з дати оформлення Акту рекламації.
            </li>
          </ul>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.6.</h2>
          <p className={style.provisionDescription}>
            Замовлення-наряд на встановлення Товару на СТО має містити дані
            відповідно до ст. 25 Закону України «Про автомобільний транспорт»:
          </p>
          <ul className={style.list}>
            <li>2.6.1. Найменування і реквізити СТО.</li>
            <li>2.6.2. Прізвище та ініціали замовника робіт на СТО.</li>
            <li>2.6.3. Держномер, VIN-код, рік випуску автомобіля.</li>
            <li>2.6.4. Дата заїзду на СТО.</li>
            <li>2.6.5. Пробіг на момент заїзду на СТО.</li>
            <li>2.6.6. Перелік виконаних робіт та їх вартість.</li>
            <li>
              2.6.7. Перелік складових частин (матеріалів), використаних під час
              виконання робіт.
            </li>
            <li>
              2.6.8. Прізвище, ініціали та підпис відповідальної особи СТО,
              печатку СТО (за наявності).
            </li>
            <li>2.6.9. Підпис Покупця про приймання виконаних робіт.</li>
          </ul>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.7.</h2>
          <p className={style.provisionDescription}>
            Акт рекламації має містити відомості згідно зразка, який надається
            Продавцем Покупцю.
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>2.8.</h2>
          <p className={style.provisionDescription}>
            Гарантія не розповсюджується на наступні випадки:
          </p>
          <ul className={style.list}>
            <li>
              2.8.1. Пошкодження Товару внаслідок ДТП, під час монтажу або
              демонтажу, порушення правил експлуатації, установки з порушенням
              технології виконання ремонтних робіт.
            </li>
            <li>
              2.8.2. Пошкодження Товару, який належить до паливної системи та
              системи вихлопу газів автомобіля, що виникло внаслідок
              застосування низькоякісного палива.
            </li>
            <li>
              2.8.3. Пошкодження підвіски і рульового управління, які виникли
              внаслідок неналежної експлуатації.
            </li>
            <li>
              2.8.4. Претензій до шуму (скрипу) гальм, наявності великої
              кількості абразивного пилу.
            </li>
            <li>
              2.8.5. Претензій до зовнішніх пошкоджень Товарів, які є склом
              кузова та приладами освітлення.
            </li>
            <li>
              2.8.6. Претензій до несправності або корозії Товару, які виникли
              внаслідок впливу промислових і хімічних викидів.
            </li>
            <li>
              2.8.7. Експлуатаційного зносу і нормальної зміни стану, в тому
              числі старіння, такого Товару, як щітки склоочисника.
            </li>
            <li>
              2.8.8. Недотримання вимог інструкцій та рекомендацій виробника.
            </li>
            <li>
              2.8.9. Відсутності у замовленні-наряді при монтажі Товару відмітки
              про проведення робіт.
            </li>
            <li>
              2.8.10. Втручання в вузли, змінення конструкції або властивостей
              матеріалу виробу.
            </li>
            <li>
              2.8.11. Належності Товару до категорії витратних матеріалів.
            </li>
            <li>2.8.12. Недотримання Покупцем вимог, за яких діє гарантія.</li>
          </ul>
        </div>
      </div>
      <div className={style.provisionsContainer}>
        <h1 className={style.title}>
          3. ПОРЯДОК РОЗГЛЯДУ ГАРАНТІЙНИХ ПРЕТЕНЗІЙ
        </h1>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>3.1.</h2>
          <p className={style.provisionDescription}>
            Покупець має право звернутися з гарантійною претензією до виробника
            Товару або Продавця за місцем купівлі Товару.
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>3.2.</h2>
          <p className={style.provisionDescription}>
            Покупець оформляє гарантійну претензію в письмовій формі.
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>3.3.</h2>
          <p className={style.provisionDescription}>
            Продавець розглядає гарантійну претензію і приймає рішення, як
            правило, в момент звернення, а у разі необхідності проведення
            технічної перевірки причин виходу товару з ладу, - в строк згідно
            чинного законодавства України.
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>3.4.</h2>
          <p className={style.provisionDescription}>
            Для розгляду гарантійної претензії Покупець надає дефектний Товар та
            документи згідно пп. 2.6, 2.7 даного Положення.
          </p>
          <ul className={style.list}>
            <li>
              3.4.1. Товар має бути наданим в тій же комплектності, в якій він
              був отриманий від Продавця.
            </li>
            <li>
              3.4.2. Окремі вузли і агрегати мають бути в зібраному стані, без
              слідів стороннього втручання, а в разі недотримання цієї вимоги
              має бути надане мотивоване пояснення.
            </li>
          </ul>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>3.5.</h2>
          <p className={style.provisionDescription}>
            Гарантійні претензії до Товару вартістю до 3000 грн., окрім
            електричних виробів, розглядаються у спрощений спосіб, тобто без
            надання документів, вказаних у пп. 2.6, 2.7, лише за наданням
            дефектного Товару.
          </p>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>3.6.</h2>
          <p className={style.provisionDescription}>
            У разі задоволення гарантійної претензії Продавець в строк згідно
            діючого законодавства України надає Товар на заміну дефектного або
            повертає Покупцю грошові кошти.
          </p>
        </div>

        <h1 className={style.title}>4. ПОВЕРНЕННЯ ТОВАРУ НАЛЕЖНОЇ ЯКОСТІ</h1>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>4.1.</h2>
          <p className={style.provisionDescription}>
            Товар може бути повернуто у випадках та на умовах, які визначені
            чинним законодавством України та Договором:
          </p>
          <ul className={style.list}>
            <li>
              4.1.1. Покупець звернувся щодо повернення Товару протягом 14
              (чотирнадцяти) днів, не рахуючи дня прийому-передачі.
            </li>
            <li>
              4.1.2. Товар має товарний вигляд без слідів експлуатації або
              монтажу, ціле пакування, ярлики.
            </li>
          </ul>
        </div>

        <div className={style.provision}>
          <h2 className={style.provisionTitle}>4.2.</h2>
          <p className={style.provisionDescription}>
            Повернення Товару має обов’язково бути узгодженим з Продавцем.
          </p>
          <ul className={style.list}>
            <li>
              4.2.1. В ході узгодження визначається порядок передачі Товару:
              строки, супроводжуючі документи, перевізник тощо.
            </li>
            <li>
              4.2.2. Усі ризики внаслідок неузгодженого повернення Товару несе
              Покупець.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PrivaticyPolicy;
