import React from "react";
import style from "./CatalogContainer.module.scss";
import Filters from "./Filters/Filters";
import ItemsList from "./ItemsList/ItemsList";
function CatalogContainer() {
  return (
    <div className={style.container}>
      <Filters />
      <ItemsList />
    </div>
  );
}

export default CatalogContainer;
