import React from "react";
import CategoriesSlider from "../components/CategoriesSlider/CategoriesSlider";
import GoodsSlider from "../components/GoodsSlider/GoodsSlider";
import Companies from "../components/Companies/Companies";

function Home() {
  return (
    <>
      <CategoriesSlider />
      <GoodsSlider title="Популярні" />
      {/* <Companies /> */}
    </>
  );
}

export default Home;
