import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import style from "./Catalog.module.scss";
import CatalogContainer from "../../components/CatalogContainer/CatalogContainer";

function Catalog() {
  const dispatch = useDispatch();
  const currentCategory = useSelector(
    (state) => state.category.selectedCategory
  );

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.navigation}>
          <Link className={style.link} to="/">
            Акумулятори
          </Link>
          <span className={style.linkText}> &gt;</span>
          {currentCategory}
        </div>
        <div className="">
          <CatalogContainer />
        </div>
      </div>
    </div>
  );
}

export default Catalog;
