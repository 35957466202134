import React from "react";
import style from "./CartPopup.module.scss";
import { useDispatch, useSelector } from "react-redux";
import x from "../../assets/x.svg";
import { Delete, ShoppingCartCheckout } from "@mui/icons-material";
import {
  removeItemFromCart,
  updateItemQuantity,
} from "../../components/store/actions/cartActions"; // Імпорт action для оновлення кількості товару
import { togglePopup } from "../../components/store/actions/cartPopupActions.js";
import { Link } from "react-router-dom";
import ImageGet from "../ImageGet/ImageGet.jsx";

const CartPopup = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);

  const calculateTotalPrice = () => {
    return cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };

  const handleQuantityChange = (item, quantity) => {
    if (quantity > 0) {
      dispatch(updateItemQuantity(item.id, quantity)); // Виклик функції для оновлення кількості
    }
  };

  const togglePopupFunc = () => {
    dispatch(togglePopup());
  };

  return (
    <div
      className={style.wrapper}
      onClick={() => {
        togglePopupFunc();
      }}
    >
      <div className={style.popup} onClick={(e) => e.stopPropagation()}>
        <div className={style.top}>
          <h3>Кошик</h3>
          <button
            onClick={() => {
              togglePopupFunc();
            }}
          >
            <img src={x} alt="" />
          </button>
        </div>
        <div className={style.list}>
          {cartItems.map((item, index) => (
            <div key={index} className={style.item}>
              <div className={style.firstInfo}>
                <ImageGet className={style.img} src={item.img} alt="" />
                <span className={style.name}>{item.name}</span>
              </div>

              <div className={style.secondInfo}>
                <p className={style.price}>
                  {item.price}
                  <span className={style.currency}> ₴</span>
                </p>
                <div className={style.quantityControl}>
                  <button
                    onClick={() =>
                      handleQuantityChange(item, item.quantity - 1)
                    }
                  >
                    -
                  </button>
                  <span>{item.quantity}</span>
                  <button
                    onClick={() =>
                      handleQuantityChange(item, item.quantity + 1)
                    }
                  >
                    +
                  </button>
                </div>
                <button
                  className={style.delete}
                  onClick={() => {
                    dispatch(removeItemFromCart(item));
                  }}
                >
                  <Delete />
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className={style.orderContainer}>
          <button
            className={style.continue}
            onClick={() => {
              togglePopupFunc();
            }}
          >
            Продовжити покупки
          </button>
          <div className={style.orderButtonContainer}>
            <p>
              {calculateTotalPrice()} <span className={style.currency}> ₴</span>
            </p>
            <Link
              to={"/order"}
              onClick={() => {
                togglePopupFunc();
              }}
            >
              <button className={style.order}>Замовити</button>
            </Link>
          </div>
        </div>
        <div className={style.bottom}>
          <div className={style.bottomLeft}>
            <div className={style.bottomIcon}>
              <ShoppingCartCheckout />
            </div>
            <div className={style.bottomInfo}>
              <p>Швидке замовлення</p>
              <p>
                Вкажіть свій номер телефону та найближчим часом наш менеджер
                зв'яжеться з Вами. <br />
                Всі товари будуть додані на замовлення автоматично.
              </p>
            </div>
          </div>
          <div className={style.bottomRight}>
            <input className={style.bottomInput} type="text" />
            <button className={style.order}>Замовити</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPopup;
