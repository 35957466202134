import { createStore, combineReducers } from "redux";
import productReducer from "./reducers/productReducer";
import categoryReducer from "./reducers/categoryReducer";
import filterReducer from "./reducers/filterReducer";
import cartReducer from "./reducers/cartReducer";
import compareReducer from "./reducers/compareReducer";
import activeItemReducer from "./reducers/activeItemReducer";
import cartPopupReducer from "./reducers/cartPopupReducer";
import customerReducer from "./reducers/customerReducer";
import deliveryOptionReducer from "./reducers/deliveryOptionReducer";

const rootReducer = combineReducers({
  products: productReducer,
  category: categoryReducer,
  filters: filterReducer,
  cart: cartReducer,
  compare: compareReducer,
  activeItem: activeItemReducer,
  cartPopup: cartPopupReducer,
  customer: customerReducer,
  deliveryOption: deliveryOptionReducer,
});

const store = createStore(rootReducer);

export default store;
