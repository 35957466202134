import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./pages/Home.jsx";
import Catalog from "./pages/Catalog/Catalog.jsx";
import "./App.scss";
import Footer from "./components/Footer/Footer.jsx";
import Item from "./pages/Item/Item.jsx";
import Compare from "./pages/Compare/Comapre.jsx";
import Client from "./pages/Client/Client.jsx";
import OrderPage from "./pages/OrderPage/OrderPage.jsx";
import { useSelector } from "react-redux";
import ScrollUp from "./components/Widgets/ScrollUp/ScrollUp.jsx";
import ContactButton from "./components/Widgets/ContactButton/ContactButton.jsx";
import ScrollToTop from "./components/Widgets/ScrollToTop.jsx";
import CallMiddle from "./components/Widgets/CallMiddle/CallMiddle.jsx";
import CallBottom from "./components/Widgets/CallBottom/Callbottom.jsx";
import CartPopup from "./components/CartPopup/CartPopup.jsx";

function App() {
  const cartData = useSelector((state) => state.cart.items);
  const isPopupOpen = useSelector((state) => state.cartPopup.isOpen);
  const [showCallMiddle, setShowCallMiddle] = useState(false);
  const [showCallBottom, setShowCallBottom] = useState(false);

  useEffect(() => {
    const randomComponent = Math.random() < 0.5 ? "CallMiddle" : "CallBottom";
    if (randomComponent === "CallMiddle") {
      setShowCallMiddle(true);
    } else {
      setShowCallBottom(true);
    }
  }, []);

  const timeToOpen = 100;

  const handleCallMiddleClose = () => {
    setShowCallMiddle(false);
    setTimeout(() => {
      setShowCallBottom(true);
      console.log("opening CallBottom");
    }, timeToOpen);
  };

  const handleCallBottomClose = () => {
    setShowCallBottom(false);
    setTimeout(() => {
      setShowCallMiddle(true);
    }, timeToOpen);
  };
  console.log(isPopupOpen);
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/compare" element={<Compare />} />
          <Route path="/client" element={<Client />} />
          <Route path="/order" element={<OrderPage />} />
          <Route path="/product/:id" element={<Item />} />
        </Routes>
        {showCallMiddle && <CallMiddle onClose={handleCallMiddleClose} />}
        {showCallBottom && <CallBottom onClose={handleCallBottomClose} />}
        <ScrollToTop />
        <ContactButton />
        <ScrollUp />
        <Footer />

        {isPopupOpen && <CartPopup />}
      </div>
    </Router>
  );
}

export default App;
