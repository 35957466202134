import React from "react";
import style from "./Comapre.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { removeItemFromCompare } from "../../components/store/actions/compareActions";
import ImageGet from "../../components/ImageGet/ImageGet";
function Compare() {
  const compareData = useSelector((state) => state.compare.items);

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <h3>Порівняння товарів</h3>
        <div className={style.listContainer}>
          {compareData.map((item, index) => (
            <div key={index} className={style.item}>
              <div className={style.mainInfo}>
                <ImageGet className={style.img} src={item.img} alt="" />
                <p className={style.name}>{item.name}</p>
              </div>

              <div className={style.specTab}>
                <h4 className={style.specTitle}>Характеристики</h4>
                <div className={style.specsContainer}>
                  <div className={style.specRow}>
                    <span className={style.specName}>Ємність:</span>
                    <span>{item.capacity}</span>
                  </div>
                  <div className={style.specRow}>
                    <span className={style.specName}>Пусковий струм:</span>
                    <span>{item.startingCurrent}</span>
                  </div>
                  <div className={style.specRow}>
                    <span className={style.specName}>Плюсова клема:</span>
                    <span>{item.positiveTerminal}</span>
                  </div>
                  <div className={style.specRow}>
                    <span className={style.specName}>Напруга:</span>
                    <span>{item.voltage}</span>
                  </div>
                  <div className={style.specRow}>
                    <span className={style.specName}>Тип корпусу:</span>
                    <span>{item.caseMadeIn}</span>
                  </div>
                  <div className={style.specRow}>
                    <span className={style.specName}>Вага:</span>
                    <span>{item.weight}</span>
                  </div>
                  <div className={style.specRow}>
                    <span className={style.specName}>Довжина:</span>
                    <span>{item.length}</span>
                  </div>
                  <div className={style.specRow}>
                    <span className={style.specName}>Висота:</span>
                    <span>{item.height}</span>
                  </div>
                  <div className={style.specRow}>
                    <span className={style.specName}>Термін гарантії:</span>
                    <span>{item.warrantyPeriod}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Compare;
