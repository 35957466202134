import React, { useState, useEffect } from "react";
import style from "./SearchBar.module.scss";
import fuzzysearch from "fuzzysearch";
import { useSelector } from "react-redux";
import { Search } from "@mui/icons-material";
import { Link } from "react-router-dom";

const SearchBar = ({ closeHeader }) => {
  const items = useSelector((state) => state.products);
  const [query, setQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState(items);
  const [isActive, setIsActive] = useState(false);
  const inputRef = React.useRef(null); // Використовуємо реф для інпуту

  const handleSearch = (e) => {
    const query = e.target.value;
    setQuery(query);

    if (query) {
      const filtered = items.filter((item) =>
        fuzzysearch(query.toLowerCase(), item.name.toLowerCase())
      );
      setFilteredItems(filtered);
    } else {
      setFilteredItems([]);
    }

    setIsActive(true);
  };

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      inputRef.current.blur(); // Втрачаємо фокус з інпуту
    }
  };

  const getImage = (imagePath) => {
    try {
      return require(`../../assets/${imagePath}`);
    } catch (err) {
      console.error("Error loading image:", err);
      return null;
    }
  };

  const handleMouseDown = (e) => {
    // Запобігаємо втраті фокусу при кліку на результат
    e.preventDefault();
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className={style.container}>
      <div className={`${style.wrapper} ${isActive ? style.active : ""}`}></div>
      <div className={style.search}>
        <button>
          <Search className={style.searchIcon} />
        </button>
        <input
          ref={inputRef} // Додаємо реф до інпуту
          className={style.searchInput}
          type="text"
          value={query}
          onChange={handleSearch}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder="Пошук..."
        />
        {query && (
          <div className={style.resultContainer}>
            {filteredItems.length > 0 ? (
              <ul className={style.results}>
                {filteredItems.map((item, index) => (
                  <li
                    className={style.resultsItem}
                    key={index}
                    onMouseDown={handleMouseDown}
                  >
                    <Link
                      to={`/product/${item.id}`}
                      onClick={() => {
                        inputRef.current.blur();
                        closeHeader(); // Закриваємо хедер
                      }}
                    >
                      <img
                        className={style.resultImg}
                        src={getImage(item.img)}
                        alt=""
                      />
                    </Link>
                    <div className={style.itemInfo}>
                      <Link
                        to={`/product/${item.id}`}
                        onClick={() => {
                          inputRef.current.blur();
                          closeHeader(); // Закриваємо хедер
                        }}
                      >
                        <p>{item.name}</p>
                      </Link>
                      <p className={style.price}>
                        {item.price} <span className={style.currency}>₴</span>
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className={style.noSearch}>
                Немає результатів, схожих на ваш запит.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
