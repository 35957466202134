import React from "react";
import style from "./Item.module.scss";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  Balance,
  AddShoppingCartOutlined,
  RemoveShoppingCartOutlined,
  StarBorder,
  Star,
  CheckCircle,
} from "@mui/icons-material";
import {
  addItemToCart,
  removeItemFromCart,
} from "../../../store/actions/cartActions";
import {
  addItemToCompare,
  removeItemFromCompare,
} from "../../../store/actions/compareActions";
import { setProduct } from "../../../store/actions/activeItemActions.js";
import { Link } from "react-router-dom";
import { togglePopup } from "../../../store/actions/cartPopupActions.js";

const Item = ({ product }) => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const compareItems = useSelector((state) => state.compare.items);

  const isInCart = cartItems.some((item) => item.id === product.id);
  const isInCompare = compareItems.some((item) => item.id === product.id);
  const getImage = (imagePath) => {
    try {
      return require(`../../../../assets/${imagePath}`);
    } catch (err) {
      console.error("Error loading image:", err);
      return null;
    }
  };
  const handleCartClick = () => {
    if (isInCart) {
      dispatch(removeItemFromCart(product));
    } else {
      dispatch(addItemToCart(product));
    }
  };
  const handleSetActiveProduct = () => {
    dispatch(setProduct(product));
  };
  const handleCompareClick = () => {
    if (isInCompare) {
      dispatch(removeItemFromCompare(product));
    } else {
      dispatch(addItemToCompare(product));
    }
  };
  return (
    <div className={style.item}>
      <Link
        className={style.imgLink}
        to={`/product/${product.id}`}
        onClick={() => {
          handleSetActiveProduct();
        }}
      >
        <img className={style.itemImg} src={getImage(product.img)} alt="" />
      </Link>
      <Link
        to={`/product/${product.id}`}
        onClick={() => {
          handleSetActiveProduct();
        }}
      >
        <p className={style.name}>{product.name}</p>
      </Link>

      <div className={style.reviews}>
        <Star />
        <Star />
        <Star />
        <Star />
        <Star />
        {/* <StarBorder /> */}
      </div>
      <div className={style.bottom}>
        <p className={style.price}>
          {product.price}
          <span className={style.currency}> ₴</span>
          <p className={style.inStocks}>В наявності</p>
        </p>
        <Button
          className={style.order}
          variant="contained"
          onClick={() => {
            handleCartClick();
            dispatch(togglePopup());
          }}
        >
          Придбати
        </Button>
      </div>
      <div className={style.buttons}>
        <div
          className={style.compareIcon}
          onClick={() => {
            handleCompareClick();
          }}
        >
          <Balance />
          {isInCompare ? <CheckCircle className={style.comapreChecked} /> : ""}
        </div>
        <div className={style.cartIcon}>
          {isInCart ? (
            <RemoveShoppingCartOutlined
              onClick={() => {
                handleCartClick();
              }}
            />
          ) : (
            <AddShoppingCartOutlined
              onClick={() => {
                handleCartClick();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Item;
