// Popup.jsx
import React from "react";
import style from "./EmailSendPopup.module.scss";
import { CheckCircle } from "@mui/icons-material";

const EmailSendPopup = ({ message, onClose }) => {
  return (
    <div className={style.popup}>
      <div className={style.popupContent}>
        <h2>{message}</h2>
        <CheckCircle className={style.icon} />

        <button onClick={onClose}>Закрити</button>
      </div>
    </div>
  );
};

export default EmailSendPopup;
