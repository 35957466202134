export const addItemToCompare = (product) => {
  return {
    type: "ADD_ITEM_TO_COMPARE",
    payload: product,
  };
};

export const removeItemFromCompare = (product) => {
  return {
    type: "REMOVE_ITEM_FROM_COMPARE",
    payload: product,
  };
};
