import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addFilter,
  removeFilter,
  setPriceFilter,
} from "../../store/actions/filterActions";
import style from "./Filters.module.scss";
import Price from "./Price/Price";
import "./style.css";

const Filters = () => {
  const data = useSelector((state) => state.products);
  const activeFilters = useSelector((state) => state.filters.activeFilters);
  const priceFilter = useSelector((state) => state.filters.priceFilter);
  const dispatch = useDispatch();

  const prices = data.map((product) => parseFloat(product.price));
  const defaultMinPrice = Math.min(...prices);
  const defaultMaxPrice = Math.max(...prices);

  const [minPrice, setMinPrice] = useState(defaultMinPrice);
  const [maxPrice, setMaxPrice] = useState(defaultMaxPrice);
  const [checkboxState, setCheckboxState] = useState({});

  const filterData = {
    capacity: {
      name: "Ємність (Ah)",
      options: ["До 60", "60-100", "100-150", "Більше 150"],
    },
    // coldCrankingAmps: {
    //   name: "Пусковий струм (A)",
    //   options: ["До 500", "500-1000", "Більше 1000"],
    // },
  };

  const handleFilterClick = (filterType, filterValue) => {
    const checkboxKey = `${filterType}-${filterValue}`;
    const isActive = checkboxState[checkboxKey];

    if (isActive) {
      dispatch(removeFilter(filterType, filterValue));
    } else {
      dispatch(addFilter(filterType, filterValue));
    }

    setCheckboxState((prevState) => ({
      ...prevState,
      [checkboxKey]: !prevState[checkboxKey],
    }));
  };

  const handlePriceChange = (minPrice, maxPrice) => {
    setMinPrice(minPrice);
    setMaxPrice(maxPrice);
    dispatch(setPriceFilter(minPrice, maxPrice));
  };

  const handleActiveFilterClick = (filterType, filterValue) => {
    handleFilterClick(filterType, filterValue);
  };

  return (
    <div className={style.container}>
      <div className="">
        {" "}
        <p className={style.title}>Фільтри</p>
        <div className={style.activeFilters}>
          {activeFilters.map((filter, index) => (
            <div
              key={index}
              className={style.activeFilter}
              onClick={() =>
                handleActiveFilterClick(filter.filterType, filter.filterValue)
              }
            >
              {filter.filterType === "price"
                ? `Ціна: ${filter.minPrice} - ${filter.maxPrice}`
                : `${filterData[filter.filterType].name}: ${
                    filter.filterValue
                  }`}
            </div>
          ))}
        </div>
      </div>

      <Price
        max={defaultMaxPrice}
        min={defaultMinPrice}
        onPriceChange={handlePriceChange}
      />
      <div className={style.filterName}>
        {Object.keys(filterData).map((filterType, index) => (
          <div key={index} className={style.filterContainer}>
            <h5 className={style.filterTitle}>{filterData[filterType].name}</h5>
            <div className={style.filter}>
              {filterData[filterType].options.map((filterValue, subIndex) => (
                <div className={style.filterItem} key={subIndex}>
                  <label className="container">
                    <input
                      type="checkbox"
                      data-value={filterValue}
                      checked={
                        checkboxState[`${filterType}-${filterValue}`] || false
                      }
                      onChange={(e) => {
                        const value = e.target.getAttribute("data-value");
                        handleFilterClick(filterType, value);
                      }}
                    />
                    <svg viewBox="0 0 64 64" height="1em" width="1em">
                      <path
                        d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                        pathLength="575.0541381835938"
                        className="path"
                      ></path>
                    </svg>
                  </label>
                  {filterValue}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Filters;
