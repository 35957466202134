import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import style from "./OrderPage.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Delete } from "@mui/icons-material";
import {
  removeItemFromCart,
  updateItemQuantity,
} from "../../components/store/actions/cartActions";
import { Link } from "react-router-dom";
import { setDeliveryOption } from "../../components/store/actions/deliveryOptionActions";
import { setActiveTab } from "../../components/store/actions/customerActions";

import EmailSendPopup from "./EmailSendPopup/EmailSendPopup";

const serviceId = "service_jc5hu6l";
const templateId = "template_nvbysxi";
const userId = "qht2yz6dbIHWWlekw";

function OrderPage() {
  const dispatch = useDispatch();
  const deliveryOption = useSelector((state) => state.deliveryOption.option);
  const cartItems = useSelector((state) => state.cart.items);

  const [formData, setFormData] = useState({
    customerType: "new",
    firstName: "",
    phoneNumber: "",
    email: "",
    shippingOption: deliveryOption,
    city: "",
    paymentOption: "",
    comment: "",
    address: "",
    quantity: "",
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleClientLinkClick = (tab) => {
    dispatch(setActiveTab(tab));
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      shippingOption: deliveryOption,
    }));
  }, [deliveryOption]);

  const getImage = (imagePath) => {
    try {
      return require(`../../assets/${imagePath}`);
    } catch (err) {
      console.error("Error loading image:", err);
      return null;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + item.price, 0);
  };

  const formattedString = cartItems
    .map(
      (item) =>
        `Продукт: ${item.name || ""}\nЦіна: ${item.price || 0} ₴, Кількість: ${
          item.quantity
        }`
    )
    .join("\n");

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailData = {
      firstName: formData.firstName || "",
      customerType: formData.customerType || "",
      phoneNumber: formData.phoneNumber || "",
      email: formData.email || "",
      shippingOption: formData.shippingOption || "",
      city: formData.city || "",
      address: formData.address || "",
      paymentOption: formData.paymentOption || "",
      comment: formData.comment || "",
      cartItems: formattedString,
      totalPrice: calculateTotal() || 0,
    };

    emailjs
      .send(serviceId, templateId, emailData, userId)
      .then((response) => {
        console.log("Email sent successfully:", response);
        setIsPopupOpen(true); // Показуємо попап при успішній відправці
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <h1 className={style.pageTitle}>Оформити замовлення</h1>
        <div className={style.main}>
          <div className={style.orderForm}>
            <form onSubmit={handleSubmit} className={style.form}>
              <div className={style.formItem}>
                <h4>
                  <span>1</span> Ваші контактні дані
                </h4>
                <div className={style.radioGroup}>
                  <label
                    className={`${style.radioLabel} ${
                      formData.customerType === "new" ? style.active : ""
                    }`}
                  >
                    <input
                      type="radio"
                      name="customerType"
                      value="new"
                      checked={formData.customerType === "new"}
                      onChange={handleChange}
                      className={style.radioInput}
                    />
                    Я новий покупець
                  </label>
                  <label
                    className={`${style.radioLabel} ${
                      formData.customerType === "regular" ? style.active : ""
                    }`}
                  >
                    <input
                      type="radio"
                      name="customerType"
                      value="regular"
                      checked={formData.customerType === "regular"}
                      onChange={handleChange}
                      className={style.radioInput}
                    />
                    Я постійний покупець
                  </label>
                </div>
                <div className={style.personalInfo}>
                  <input
                    placeholder="ПІБ"
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                  <input
                    placeholder="Електронна пошта"
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <input
                    placeholder="Ваш номер телефону"
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className={style.formItem}>
                <h4>
                  <span>2</span> Спосіб доставки
                </h4>

                <div>
                  <label
                    className={`${style.deliveryRadioLabel} ${
                      formData.shippingOption === "Нова пошта"
                        ? style.deliveryActive
                        : ""
                    }`}
                  >
                    <input
                      type="radio"
                      name="shippingOption"
                      value="Нова пошта"
                      checked={formData.shippingOption === "Нова пошта"}
                      onChange={() => {
                        dispatch(setDeliveryOption("Нова пошта"));
                        setFormData({
                          ...formData,
                          shippingOption: "Нова пошта",
                        });
                      }}
                      className={style.deliveryRadioInput}
                    />
                    Самовивіз з нової пошти (лише вантажні відділення)
                  </label>
                  <label
                    className={`${style.deliveryRadioLabel} ${
                      formData.shippingOption === "Кур'єр по Вінниці"
                        ? style.deliveryActive
                        : ""
                    }`}
                  >
                    <input
                      type="radio"
                      name="shippingOption"
                      value="Кур'єр по Вінниці"
                      checked={formData.shippingOption === "Кур'єр по Вінниці"}
                      onChange={() => {
                        dispatch(setDeliveryOption("Кур'єр по Вінниці"));
                        setFormData({
                          ...formData,
                          shippingOption: "Кур'єр по Вінниці",
                        });
                      }}
                      className={style.deliveryRadioInput}
                    />
                    Вінниця та Вінницька область
                  </label>
                  {(formData.shippingOption === "Нова пошта" ||
                    formData.shippingOption === "Кур'єр по Вінниці") && (
                    <div className={style.addressInput}>
                      <input
                        placeholder={
                          formData.shippingOption === "Нова пошта"
                            ? "Введіть населений пункт та відділення"
                            : "Введіть адресу"
                        }
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className={style.formItem}>
                <h4>
                  <span>3</span> Спосіб оплати
                </h4>
                <div>
                  <label
                    className={`${style.paymentRadioLabel} ${
                      formData.paymentOption === "cash_on_delivery"
                        ? style.paymentActive
                        : ""
                    }`}
                  >
                    <input
                      type="radio"
                      name="paymentOption"
                      value="cash_on_delivery"
                      checked={formData.paymentOption === "cash_on_delivery"}
                      onChange={handleChange}
                      className={style.paymentRadioInput}
                    />
                    Оплата при отриманні
                  </label>
                  <label
                    className={`${style.paymentRadioLabel} ${
                      formData.paymentOption === "legal_entities"
                        ? style.paymentActive
                        : ""
                    }`}
                  >
                    <input
                      type="radio"
                      name="paymentOption"
                      value="legal_entities"
                      checked={formData.paymentOption === "legal_entities"}
                      onChange={handleChange}
                      className={style.paymentRadioInput}
                    />
                    Для юридичних осіб
                  </label>
                </div>
              </div>

              <div className={style.formItem}>
                <h4>
                  <span>4</span>Список товарів
                </h4>
                <div className={style.cartList}>
                  <div className={style.cartList}>
                    {cartItems.map((item, index) => (
                      <div key={index} className={style.item}>
                        <Link to={`/product/${item.id}`}>
                          <img
                            className={style.img}
                            src={getImage(item.img)}
                            alt=""
                          />
                        </Link>
                        <Link to={`/product/${item.id}`}>
                          <span className={style.name}>{item.name}</span>
                        </Link>

                        <p className={style.price}>
                          {item.price}
                          <span className={style.currency}> ₴</span>
                        </p>

                        <div className={style.quantity}>
                          <div
                            className={style.decrement}
                            onClick={() =>
                              dispatch(
                                updateItemQuantity(item.id, item.quantity - 1)
                              )
                            }
                            disabled={item.quantity <= 1}
                          >
                            -
                          </div>
                          <input
                            type="number"
                            value={item.quantity}
                            min="1"
                            onChange={(e) =>
                              dispatch(
                                updateItemQuantity(
                                  item.id,
                                  Number(e.target.value)
                                )
                              )
                            }
                            className={style.quantityInput}
                          />
                          <div
                            className={style.increment}
                            onClick={() =>
                              dispatch(
                                updateItemQuantity(item.id, item.quantity + 1)
                              )
                            }
                          >
                            +
                          </div>
                        </div>

                        <button
                          className={style.delete}
                          onClick={() => {
                            dispatch(removeItemFromCart(item));
                          }}
                        >
                          <Delete />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <textarea
                    className={style.comment}
                    placeholder="Коментар до замовлення"
                    name="comment"
                    value={formData.comment}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </form>
          </div>
          <div className={style.orderConfirm}>
            <h3 className={style.confirmTitle}>Замовлення</h3>
            <div className={style.orderSummary}>
              <p>
                <span>Загальна кількість товарів:</span>
                <span>{cartItems.length}</span>
              </p>
              <p>
                <span>До оплати:</span>
                <span>
                  {calculateTotal()}
                  <span className={style.currency}> ₴</span>
                </span>
              </p>
            </div>
            <div className={style.confirmation}>
              <button
                className={style.submit}
                type="submit"
                onClick={handleSubmit}
              >
                Замовити
              </button>
              <div className={style.agreement}>
                *Підтверджуючи замовлення, я приймаю умови{" "}
                <Link
                  className={style.rights}
                  to={"/client"}
                  onClick={() => handleClientLinkClick("Контакти")}
                >
                  публічної оферти
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <EmailSendPopup
          message="Ваше замовлення успішно надіслано!"
          onClose={() => setIsPopupOpen(false)}
        />
      )}
    </div>
  );
}

export default OrderPage;
