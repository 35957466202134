// actions/filterActions.js
export const ADD_FILTER = "ADD_FILTER";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const SET_PRICE_FILTER = "SET_PRICE_FILTER";

export const addFilter = (filterType, filterValue) => ({
  type: ADD_FILTER,
  payload: { filterType, filterValue },
});

export const removeFilter = (filterType, filterValue) => ({
  type: REMOVE_FILTER,
  payload: { filterType, filterValue },
});

export const setPriceFilter = (minPrice, maxPrice) => ({
  type: SET_PRICE_FILTER,
  payload: { filterType: "price", minPrice, maxPrice },
});
