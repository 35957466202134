import React, { useState, useEffect } from "react";
import style from "./CallMiddle.module.scss";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo.png";

const CallMiddle = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(3600); // 1 година = 3600 секунд

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Конфігурація для відправки повідомлення
    const serviceId = "service_v6otwss"; // ID вашого сервісу з EmailJS
    const templateId = "template_xdztcn8"; // ID вашого шаблону з EmailJS
    const userId = "vaxjEsD9LApBU7kgg"; // ID вашого користувача з EmailJS

    // Дані, які будуть використані у шаблоні листа
    const templateParams = {
      phoneNumber: phoneNumber,
    };

    // Відправка листа за допомогою EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then((response) => {
        console.log("Email sent successfully:", response.status, response.text);
        // Після відправки успішно закриваємо модальне вікно
        handleClose();
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setIsSubmitting(false);
      });
  };

  // Автоматичне відкриття через 15 секунд після завантаження
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsOpen(true);
    }, 15000); // 15000 мс = 15 секунд

    return () => clearTimeout(timeoutId);
  }, []);

  // Повторне відкриття кожні 5 хвилин
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsOpen(true);
    }, 300000); // 300000 мс = 5 хвилин

    return () => clearInterval(intervalId);
  }, []);

  // Таймер
  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  const formatTime = (seconds) => {
    const h = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const m = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const s = String(seconds % 60).padStart(2, "0");
    return `${h}:${m}:${s}`;
  };

  return (
    <>
      {isOpen && <div className={style.wrapper}></div>}
      {isOpen && (
        <div className={style.modalOverlay}>
          <div className={style.modal}>
            <button className={style.closeButton} onClick={handleClose}>
              &times;
            </button>

            <form className={style.modalForm} onSubmit={handleSubmit}>
              <div className={style.formTop}>
                <div className={style.firstFormContainer}>Залиш номер</div>
                <div className="">
                  <Link to={"/"} className={style.logoContainer}>
                    <img className={style.logoImg} src={logo} alt="Logo" />
                    <div className={style.logoTextContainer}>
                      <span className={style.logoText}>WMaccum</span>
                    </div>
                    <p className={style.logoDesc}>
                      Офіційні дилери Westa та Monbat
                    </p>
                  </Link>
                </div>
              </div>
              <div className={style.secondFormContainer}>
                Отримай безкоштовну доставку в межах міста Вінниця
              </div>

              <input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Ваш номер телефону"
                className={style.phoneInput}
                required
              />

              <div className={style.afterInput}>
                Залиште свій номер телефону і отримайте безкоштовну доставку в
                межах міста
              </div>
              <div className={style.timer}>{formatTime(timeLeft)}</div>
              <button
                type="submit"
                className={style.submitButton}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Відправляється..." : "Зателефонуйте мені"}
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default CallMiddle;
