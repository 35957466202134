import { SET_DELIVERY_OPTION } from "../actions/deliveryOptionActions";

const initialState = {
  option: null,
};

const deliveryOptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DELIVERY_OPTION:
      return {
        ...state,
        option: action.payload,
      };
    default:
      return state;
  }
};

export default deliveryOptionReducer;
