import { useState, useEffect } from "react";
import style from "./ContactButton.module.scss";
import "./style.css";
import { Instagram, Phone, Telegram } from "@mui/icons-material";
const ContactButton = () => {
  return (
    <div className={style.container}>
      <nav class="menu">
        <input
          type="checkbox"
          href="#"
          class="menu-open"
          name="menu-open"
          id="menu-open"
        />
        <label class="menu-open-button" for="menu-open">
          <span class="lines line-1"></span>
          <span class="lines line-2"></span>
          <span class="lines line-3"></span>
        </label>

        <a
          href="https://t.me/copydon"
          class="menu-item blue"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Telegram />
        </a>
        <a
          href="tel:+380808989"
          className="menu-item green"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Phone />
        </a>
        <a
          href="https://t.me/copydon"
          class="menu-item red"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Instagram />
        </a>
      </nav>
    </div>
  );
};
export default ContactButton;
