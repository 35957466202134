import { productData } from "../data";

export const FETCH_PRODUCT = "FETCH_PRODUCT";

export const fetchProduct = () => (dispatch) => {
  dispatch({
    type: FETCH_PRODUCT,
    payload: productData,
  });
};
