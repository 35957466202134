import React from "react";
import style from "./Footer.module.scss";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import visaIcon from "../../assets/visa.svg";
import mastercardIcon from "../../assets/mastercard.svg";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../store/actions/customerActions";
import { setCategory } from "../store/actions/categoryActions";

function Footer() {
  const dispatch = useDispatch();

  const handleClientLinkClick = (tab) => {
    dispatch(setActiveTab(tab));
  };

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.mainInfo}>
          <div className={style.aboutInfo}>
            <a href="/" className={style.logoContainer}>
              <img className={style.logoIcon} src={logo} alt="Logo" />
              <span className={style.logoText}>WMaccum</span>
            </a>
            <div className={style.phonesContainer}>
              <p className={style.phoneNumber}>+38 (068) 231 5218</p>
            </div>
            <div className={style.workingHoursContainer}>
              <p>Графік роботи - цілодобово</p>
            </div>
          </div>

          <div className={style.linksContainer}>
            <div className={style.clientsInfo}>
              <Link to={"/client"}>
                <h5 className={style.linksTitle}>Клієнтам</h5>
              </Link>
              <Link
                to={"/client"}
                onClick={() => handleClientLinkClick("Оплата")}
              >
                Оплата
              </Link>
              {/* <Link
                to={"/client"}
                onClick={() => handleClientLinkClick("Часті запитання")}
              >
                Часті запитання
              </Link> */}
              <Link
                to={"/client"}
                onClick={() => handleClientLinkClick("Доставка")}
              >
                Доставка
              </Link>
              <Link
                to={"/client"}
                onClick={() => handleClientLinkClick("Про нас")}
              >
                Про нас
              </Link>
              <Link
                to={"/client"}
                onClick={() => handleClientLinkClick("Гарантія")}
              >
                Гарантія
              </Link>
              <Link
                to={"/client"}
                onClick={() => handleClientLinkClick("Повернення та обмін")}
              >
                Повернення та обмін
              </Link>
              <Link
                to={"/client"}
                onClick={() =>
                  handleClientLinkClick("Політика конфіденційності")
                }
              >
                Політика конфіденційності
              </Link>
              <Link
                to={"/client"}
                onClick={() => handleClientLinkClick("Контакти")}
              >
                Контакти
              </Link>
            </div>

            <div className={style.catalogueInfo}>
              <Link to={"/catalog"}>
                <h5 className={style.linksTitle}>Каталог</h5>
              </Link>
              <Link
                className={style.category}
                to="/catalog"
                onClick={() => {
                  dispatch(setCategory("Акумулятори"));
                }}
              >
                Акумулятори
              </Link>
              <Link
                className={style.category}
                to="/catalog"
                onClick={() => {
                  dispatch(setCategory("Вантажні"));
                }}
              >
                Вантажні
              </Link>
              <Link
                className={style.category}
                to="/catalog"
                onClick={() => {
                  dispatch(setCategory("Відключення світла"));
                  console.log("asd");
                }}
              >
                Відключення світла та <p>під інвертор</p>
              </Link>
            </div>
          </div>
        </div>

        <div className={style.cards}>
          <p className={style.rights}>
            <a href="/"> © WMaccum. Всі права захищені</a>
          </p>
          <div className="">
            <img src={visaIcon} alt="visa" />
            <img src={mastercardIcon} alt="mastercard" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
