import React, { useState, useEffect, act } from "react";
import style from "./Client.module.scss";
import {
  Wallet,
  Info,
  LocalShipping,
  VerticalSplit,
  WorkspacePremium,
  AssignmentReturned,
  Article,
  PermPhoneMsg,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../components/store/actions/customerActions";

import paymentImg1 from "../../assets/clientCards.webp";
import paymentImg2 from "../../assets/clientCards2.webp";
import paymentImg3 from "../../assets/clientCards3.webp";
import PrivaticyPolicy from "./PrivaticyPolicy";

function Client() {
  const activeTab = useSelector((state) => state.customer);
  console.log(activeTab);
  const dispatch = useDispatch();

  useEffect(() => {
    const path = window.location.pathname.split("/")[2];
    if (path) {
      dispatch(setActiveTab(path));
    }
  }, [dispatch]);

  const handleTabClick = (tab) => {
    dispatch(setActiveTab(tab));
  };

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <h3 className={style.pageTitle}>Клієнтам</h3>
        <div className={style.main}>
          <div className={style.nav}>
            <div
              className={`${style.tab} ${
                activeTab === "Оплата" ? style.active : ""
              }`}
              onClick={() => handleTabClick("Оплата")}
            >
              <Wallet />
              Оплата
            </div>
            {/* <div
              className={`${style.tab} ${
                activeTab === "Часті запитання" ? style.active : ""
              }`}
              onClick={() => handleTabClick("Часті запитання")}
            >
              <Info />
              Часті запитання
            </div> */}
            <div
              className={`${style.tab} ${
                activeTab === "Доставка" ? style.active : ""
              }`}
              onClick={() => handleTabClick("Доставка")}
            >
              <LocalShipping />
              Доставка
            </div>
            <div
              className={`${style.tab} ${
                activeTab === "Про нас" ? style.active : ""
              }`}
              onClick={() => handleTabClick("Про нас")}
            >
              <VerticalSplit />
              Про нас
            </div>
            <div
              className={`${style.tab} ${
                activeTab === "Гарантія" ? style.active : ""
              }`}
              onClick={() => handleTabClick("Гарантія")}
            >
              <WorkspacePremium />
              Гарантія
            </div>
            <div
              className={`${style.tab} ${
                activeTab === "Повернення та обмін" ? style.active : ""
              }`}
              onClick={() => handleTabClick("Повернення та обмін")}
            >
              <AssignmentReturned />
              Повернення та обмін
            </div>
            <div
              className={`${style.tab} ${
                activeTab === "Політика конфіденційності" ? style.active : ""
              }`}
              onClick={() => handleTabClick("Політика конфіденційності")}
            >
              <Article />
              Політика конфіденційності
            </div>
            <div
              className={`${style.tab} ${
                activeTab === "Контакти" ? style.active : ""
              }`}
              onClick={() => handleTabClick("Контакти")}
            >
              <PermPhoneMsg />
              Контакти
            </div>
          </div>
          <div className={style.info}>
            {activeTab === "Оплата" && (
              <div className={style.infoTab}>
                <h2 className={style.tabTitle}>{activeTab}</h2>
                <p className={style.paymentDesc}>
                  Використовуйте готівку, безготівку, картку, післяплату.
                </p>
                <div className={style.paymentContent}>
                  <div className={style.paymentInfoContainer}>
                    <div className={style.paymentInfoMain}>
                      <h3>Розрахунок (50/50,повна оплата,500 гривень)</h3>
                      <ul>
                        <li>
                          1. При оформленні замовлення на сайті оберіть спосіб
                          оплати
                        </li>
                        <li>
                          2. Менеджер надішле вам рахунок на вказаний Вами
                          номер,месенджер(будь-який
                          зручний(telegram,viber,Instagram та ін.).
                        </li>
                        <li>
                          3. Коли оплата за товар надійде на наш рахунок — з
                          вами зв’яжеться менеджер, щоб узгодити деталі
                          доставки.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={style.paymentInfoContainer}>
                    <div className={style.paymentIcons}>
                      <img
                        className={style.paymentImg}
                        src={paymentImg1}
                        alt=""
                      />
                    </div>
                    <div className={style.paymentInfo}>
                      <h3>500 гривень</h3>
                      <p>
                        При доставці по місту йде передплата у 500 гривень за
                        будь яку позицію товару,решта – при отриманні
                      </p>
                    </div>
                  </div>
                  <div className={style.paymentInfoContainer}>
                    <div className={style.paymentIcons}>
                      <img
                        className={style.paymentImg}
                        src={paymentImg2}
                        alt=""
                      />
                    </div>
                    <div className={style.paymentInfo}>
                      <h3>Повна оплата</h3>
                      <p>При передзамовленні товару.</p>
                    </div>
                  </div>
                  <div className={style.paymentInfoContainer}>
                    <div className={style.paymentIcons}>
                      <img
                        className={style.paymentImg}
                        src={paymentImg3}
                        alt=""
                      />
                    </div>
                    <div className={style.paymentInfo}>
                      <h3>50/50</h3>
                      <p>
                        При виборі доставки Новою Поштою ви сплачуйте 50%
                        передплати, і решту вже при отриманні у відділенні
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* {activeTab === "Часті запитання" && (
              <div className={style.infoTab}>
                <h2 className={style.tabTitle}>{activeTab}</h2>
              </div>
            )} */}
            {activeTab === "Доставка" && (
              <div className={style.infoTab}>
                <h2 className={style.tabTitle}>{activeTab}</h2>
                <h3 className={style.deliveryTitle}>
                  Обирайте товар на сайті та забирайте його у відділенні
                </h3>
                <ul className={style.deliveryList}>
                  <li>
                    1. Оформіть замовлення на сайті через кошик або за допомогою
                    менеджера
                  </li>
                  <li>
                    2. Після оформлення замовлення з вами зв’яжеться оператор(в
                    месенджері або за телефоном)
                  </li>
                  <li>
                    3. Вносите передплату (50 %) ,відправляємо Новою
                    поштою,забираєте товар
                  </li>
                </ul>
                <h3 className={style.deliveryTitle}>
                  Також існує "термінова доставка по Вінниці".
                </h3>
                <p className={style.deliveryText}>
                  Ця доставка виконується протягом години до будь-якої точки
                  Вінниці. Вартість такої доставки безкоштовно, незалежно від
                  вартості замовлених товарів, але при умові передплати у 500
                  гривень.
                  <ul>
                    <li>
                      Вінницька область (В Межах Агрономічного/Зарванців) – 150
                      гривень
                    </li>
                    <li>Калинівка 350 гривень</li>
                    <li>
                      В інших випадках вартість обговорюється індивідуально
                    </li>
                  </ul>
                </p>
              </div>
            )}
            {activeTab === "Про нас" && (
              <div className={style.infoTab}>
                <h2 className={style.tabTitle}>{activeTab}</h2>
                <div className={style.aboutContainer}>
                  <p className={style.aboutMain}>
                    Ми офіційні диллери,представники Westa та Monbat по
                    Вінницькій області
                  </p>
                  <p className={style.aboutMid}>
                    Ми не перекупаємо товар,а працюємо із виробниками напряму!
                  </p>
                  <p className={style.aboutLower}>
                    Даємо рік гарантії,а також відповідаємо за якісь товару і
                    головне – працюємо повністю ОФІЦІЙНО
                  </p>
                </div>
              </div>
            )}
            {activeTab === "Гарантія" && (
              <div className={style.infoTab}>
                <h2 className={style.tabTitle}>{activeTab}</h2>
                <div className={style.warrantyContainer}>
                  <p>Гарантійний термін на всі акумулятори становить 1 рік. </p>
                  <p>
                    У випадках, коли вибір акумулятора ви здійснюєте самостійно,
                    не керуючись нашими рекомендаціями (якщо такі були надані),
                    гарантійні умови можуть відрізнятися. Ми завжди готові
                    допомогти вам з вибором, щоб забезпечити максимальну
                    надійність і довговічність вашої покупки.
                  </p>
                </div>
              </div>
            )}
            {activeTab === "Повернення та обмін" && (
              <div className={style.infoTab}>
                <h2 className={style.tabTitle}>{activeTab}</h2>
                <div className={style.returnContainer}>
                  <p>
                    3.1. Покупець має право звернутися з гарантійною претензією
                    до виробника Товару або Продавця за місцем купівлі Товару.
                  </p>
                  <p>
                    3.2. Покупець оформляє гарантійну претензію в письмовій
                    формі.
                  </p>
                  <p>
                    3.3. Продавець розглядає гарантійну претензію і приймає
                    рішення, як правило, в момент звернення, а у разі
                    необхідності проведення технічної перевірки причин виходу
                    товару з ладу, - в строк згідно чинного законодавства
                    України.
                  </p>
                  <p>
                    3.4. Для розгляду гарантійної претензії Покупець надає
                    дефектний Товар та документи згідно пп. 2.6, 2.7 даного
                    Положення.
                  </p>
                  <ul>
                    <li>
                      3.4.1. Товар має бути наданим в тій же комплектності, в
                      якій він був отриманий від Продавця.
                    </li>
                    <li>
                      3.4.2. Окремі вузли і агрегати мають бути в зібраному
                      стані, без слідів стороннього втручання, а в разі
                      недотримання цієї вимоги із технологічних причин має бути
                      надане мотивоване пояснення.
                    </li>
                  </ul>
                  <p>
                    3.5. Гарантійні претензії до Товару вартістю до 3000 грн.,
                    окрім електричних виробів, розглядаються у спрощений спосіб,
                    тобто без надання документів, вказаних у пп. 2.6, 2.7, лише
                    за наданням дефектного Товару.
                  </p>
                  <p>
                    3.6. У разі задоволення гарантійної претензії Продавець в
                    строк згідно діючого законодавства України надає Товар на
                    заміну дефектного або повертає Покупцю грошові кошти.
                  </p>
                </div>
              </div>
            )}
            {activeTab === "Політика конфіденційності" && (
              <div className={style.infoTab}>
                <h2 className={style.tabTitle}>{activeTab}</h2>
                <PrivaticyPolicy />
              </div>
            )}
            {activeTab === "Контакти" && (
              <div className={style.infoTab}>
                <h2 className={style.tabTitle}>{activeTab}</h2>
                <div className={style.contactsContainer}>
                  <h3>Інтернет-магазин "WMAccum"</h3>
                  <p>
                    Якщо у вас виникли питання щодо роботи інтернет-магазину,
                    замовлення або сервісу - телефонуйте за номером:
                  </p>
                  <p>+380682315218</p>
                  <p>Графік роботи колл-центра:</p>
                  <p>Пн — НД: с 9:00 до 19:00</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Client;
