// reducers/filtersReducer.js
import {
  ADD_FILTER,
  REMOVE_FILTER,
  SET_PRICE_FILTER,
} from "../actions/filterActions";

const initialState = {
  activeFilters: [],
  priceFilter: { minPrice: null, maxPrice: null },
};

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FILTER:
      return {
        ...state,
        activeFilters: [...state.activeFilters, action.payload],
      };
    case REMOVE_FILTER:
      return {
        ...state,
        activeFilters: state.activeFilters.filter(
          (filter) =>
            filter.filterType !== action.payload.filterType ||
            filter.filterValue !== action.payload.filterValue
        ),
      };
    case SET_PRICE_FILTER:
      return {
        ...state,
        activeFilters: state.activeFilters
          .filter((filter) => filter.filterType !== "price")
          .concat(action.payload),
        priceFilter: {
          minPrice: action.payload.minPrice,
          maxPrice: action.payload.maxPrice,
        },
      };
    default:
      return state;
  }
};

export default filtersReducer;
