import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import style from "./Item.module.scss";
import { Button } from "@mui/material";
import {
  Balance,
  AddShoppingCartOutlined,
  RemoveShoppingCartOutlined,
  StarBorder,
  Star,
  CheckCircle,
} from "@mui/icons-material";
import {
  addItemToCart,
  removeItemFromCart,
} from "../../components/store/actions/cartActions";
import {
  addItemToCompare,
  removeItemFromCompare,
} from "../../components/store/actions/compareActions";
import novaPoshta from "../../assets/nova-icon.svg";
import courier from "../../assets/courier-icon.svg";
import ukrposhta from "../../assets/ukrposhta.svg";
import GoodsSlider from "../../components/GoodsSlider/GoodsSlider";

import { togglePopup } from "../../components/store/actions/cartPopupActions";
import ImageGet from "../../components/ImageGet/ImageGet";
import { setDeliveryOption } from "../../components/store/actions/deliveryOptionActions";
const Item = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const product = useSelector((state) =>
    state.products.find((item) => item.id === parseInt(id))
  );
  const cartItems = useSelector((state) => state.cart.items);
  const compareItems = useSelector((state) => state.compare.items);

  const isInCart = cartItems.some((item) => item.id === product.id);
  const isInCompare = compareItems.some((item) => item.id === product.id);

  const handleCartClick = () => {
    if (isInCart) {
      togglePopupFunc();
    } else {
      dispatch(addItemToCart(product));
      togglePopupFunc();
    }
  };

  const handleCompareClick = () => {
    if (isInCompare) {
      dispatch(removeItemFromCompare(product));
    } else {
      dispatch(addItemToCompare(product));
    }
  };

  const togglePopupFunc = () => {
    dispatch(togglePopup());
  };

  const [activeTab, setActiveTab] = useState("Про товар");
  const [activeImg, setActiveImg] = useState(product.img);

  if (!product) return <div>Product not found</div>;

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.top}>
          <h1 className={style.name}>{product.name}</h1>
          <div className={style.reviews}>
            <Star />
            <Star />
            <Star />
            <Star />
            <Star />
            {/* <StarBorder /> */}
          </div>
        </div>

        <div className={style.tabs}>
          <span
            className={activeTab === "Про товар" ? style.activeTab : ""}
            onClick={() => setActiveTab("Про товар")}
          >
            Про товар
          </span>
          <span
            className={activeTab === "Характеристики" ? style.activeTab : ""}
            onClick={() => setActiveTab("Характеристики")}
          >
            Характеристики
          </span>
          <span
            className={activeTab === "Фото" ? style.activeTab : ""}
            onClick={() => setActiveTab("Фото")}
          >
            Фото
          </span>
        </div>
        <div className={style.content}>
          {activeTab === "Про товар" && (
            <div className={style.about}>
              <div className={style.mainInfo}>
                <div className={style.imgContainer}>
                  <div className={style.mainImg}>
                    <ImageGet
                      className={style.mainImg}
                      src={activeImg}
                      alt={product.name}
                    />
                  </div>
                </div>
                <div className={style.additionalInfo}>
                  <div className={style.orderContainer}>
                    <div className={style.orderLeft}>
                      <span>
                        <p className={style.price}>
                          {product.price}
                          <span className={style.currency}> ₴</span>
                        </p>
                        <p className={style.inStocks}>В наявності</p>
                      </span>
                      <Button
                        className={style.order}
                        variant="contained"
                        onClick={handleCartClick}
                      >
                        Придбати
                      </Button>
                    </div>
                    <div className={style.orderRight}>
                      <div className={style.buttons}>
                        <div
                          className={style.compareIcon}
                          onClick={handleCompareClick}
                        >
                          <Balance />
                          {isInCompare && (
                            <CheckCircle className={style.comapreChecked} />
                          )}
                        </div>
                        <div className={style.cartIcon}>
                          {isInCart ? (
                            <RemoveShoppingCartOutlined
                              onClick={handleCartClick}
                            />
                          ) : (
                            <AddShoppingCartOutlined
                              onClick={handleCartClick}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={style.quickOrder}>
                    <h3 className={style.quickOrderTitle}>Швидка покупка</h3>
                    <div className={style.optionsContainer}>
                      <Link
                        to={"/order"}
                        className={style.quickOrderOption}
                        onClick={() => {
                          dispatch(setDeliveryOption("Нова пошта"));
                        }}
                      >
                        <img src={novaPoshta} alt="Нова пошта" />
                        <span>Оплата 50 на 50</span>
                      </Link>
                      <Link
                        to={"/order"}
                        className={style.quickOrderOption}
                        onClick={() => {
                          dispatch(setDeliveryOption("Кур'єр по Вінниці"));
                        }}
                      >
                        <img src={courier} alt="Кур'єр по Вінниці" />
                        <span>Кур'єр по Вінниці</span>
                      </Link>
                    </div>
                  </div>
                  <div className={style.deliveryInfo}>
                    <h4 className={style.deliveryTitle}>Доставка</h4>
                    <div className={style.deliveryOptionsContainer}>
                      <div className={style.deliveryOptions}>
                        <div className={style.deliveryName}>
                          <img src={novaPoshta} alt="" />
                          Доставка нова пошта
                        </div>
                        <div className={style.deliveryPrice}>
                          За тарифами перевізника
                        </div>
                      </div>
                      <div className={style.deliveryOptions}>
                        <div className={style.deliveryName}>
                          <img src={novaPoshta} alt="" />
                          Самовивіз Нова пошта (поштомати)
                        </div>
                        <div className={style.deliveryPrice}>
                          За тарифами перевізника
                        </div>
                      </div>

                      <div className={style.deliveryOptions}>
                        <div className={style.deliveryName}>
                          <img src={courier} alt="" />
                          Доставка кур'єром по Вінниці
                        </div>
                        <div className={style.deliveryPrice}>100 грн</div>
                      </div>
                      <div className={style.deliveryOptions}>
                        <div className={style.deliveryName}>
                          <img src={courier} alt="" /> Доставка кур'єром по
                          області
                        </div>
                        <div className={style.deliveryPrice}>100 грн</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.secondaryInfo}>
                <h4 className={style.specTitle}>Характеристики</h4>
                <div className={style.specsContainer}>
                  <div className={style.specRow}>
                    <span className={style.specName}>Ємність:</span>
                    <span>{product.capacity}</span>
                  </div>

                  <div className={style.specRow}>
                    <span className={style.specName}>Плюсова клема:</span>
                    <span>{product.positiveTerminal}</span>
                  </div>
                  <div className={style.specRow}>
                    <span className={style.specName}>Напруга:</span>
                    <span>{product.voltage}</span>
                  </div>
                  <div className={style.specRow}>
                    <span className={style.specName}>Тип корпусу:</span>
                    <span>{product.caseMadeIn}</span>
                  </div>

                  <div className={style.specRow}>
                    <span className={style.specName}>Довжина:</span>
                    <span>{product.length}</span>
                  </div>
                  <div className={style.specRow}>
                    <span className={style.specName}>Висота:</span>
                    <span>{product.height}</span>
                  </div>
                  <div className={style.specRow}>
                    <span className={style.specName}>Термін гарантії:</span>
                    <span>{product.warrantyPeriod}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === "Характеристики" && (
            <div className={style.specTab}>
              <h4 className={style.specTitle}>Характеристики</h4>
              <div className={style.specsContainer}>
                <div className={style.secondaryInfo}>
                  <h4 className={style.specTitle}>Характеристики</h4>
                  <div className={style.specsContainer}>
                    <div className={style.specRow}>
                      <span className={style.specName}>Ємність:</span>
                      <span>{product.capacity}</span>
                    </div>
                    <div className={style.specRow}>
                      <span className={style.specName}>Плюсова клема:</span>
                      <span>{product.positiveTerminal}</span>
                    </div>
                    <div className={style.specRow}>
                      <span className={style.specName}>Напруга:</span>
                      <span>{product.voltage}</span>
                    </div>
                    <div className={style.specRow}>
                      <span className={style.specName}>Тип корпусу:</span>
                      <span>{product.caseMadeIn}</span>
                    </div>
                    <div className={style.specRow}>
                      <span className={style.specName}>Довжина:</span>
                      <span>{product.length}</span>
                    </div>
                    <div className={style.specRow}>
                      <span className={style.specName}>Висота:</span>
                      <span>{product.height}</span>
                    </div>
                    <div className={style.specRow}>
                      <span className={style.specName}>Термін гарантії:</span>
                      <span>{product.warrantyPeriod}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === "Фото" && (
            <div>
              <div className={style.imgContainer}>
                <div className={style.mainImg}>
                  <ImageGet
                    className={style.mainImg}
                    src={activeImg}
                    alt={product.name}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <GoodsSlider title="Останні" />
    </div>
  );
};

export default Item;
